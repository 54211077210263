import { mapGetters, mapActions } from 'vuex';
import { throttle } from 'CommonUtils/operations/throttle';
import { debounce } from 'CommonUtils/operations/debounce';
import { useSSRContext } from 'vue';
import ICON_NAMES from 'CommonUtils/iconNames.js';
import UtilsURL from 'CommonUtils/url.js';
import UtilsProductList from 'CommonUtils/productsList.js';
import UtilsAnalytics from 'CommonUtils/analytics.js';
import UtilsPlacements from 'CommonUtils/placements';
import GRID_TYPES from 'CommonUtils/gridTypes';
import StockSchema from 'CommonUtils/schemas/stock';
import initCarousels from 'CommonUtils/siteManager/carousel';
import { DELIVERY_PARAMS } from 'CommonUtils/schemas/facetDelivery';
import { FACET_DELIVERY } from 'CommonUtils/schemas/facetDelivery';
import { mergePromosSPO } from 'CommonUtils/promotions/promotionsUtils';
import { defer } from 'CommonUtils/operations/defer';
import AlgoliaEvents from 'CommonUtils/algoliaEvents.js';

const NAVIGATION_TYPES = { FORWARD: 'FORWARD', BACKWARD: 'BACKWARD' };

const producListLogicMixin = {
  emits: ['putHandleUrl'],
  data() {
    return {
      _datalayerProducts: [],
      iconos: ICON_NAMES,
      previousPage: 1,
      page: 1,
      isLoading: false,
      loadingDirection: NAVIGATION_TYPES.FORWARD,
      fetched: {
        products: false,
        initialStock: false,
        first: false,
        last: false,
        total: 0,
      },
      fetchedPage: null,
      fetchedStock: false,
      filters: [],
      openModalFilter: false,
      elementObserver: '.footer',
      fetchedPages: [],
      productPushIndex: 1,
      firstItemPage: 0,
      paginatedPlacements: {},
      isScrolling: false,
      pageToHandleUrl: 0,
      previousPageToHandleUrl: 0,
      products: [],
      scrollObserver: null,
      scrollUnobserver: null,
      scrolledHeight: 0,
      scrolledTop: 0,
      addedPromotionsPage: [],
      showRichRelevanceContent: false,
      forceRichRelevanceRecall: false,
      richRelevanceIsEnabled: this.fetchedData.vendors?.RichRelevance?.hasRichRelevance,
      isMounted: false,
      nextBlockStart: 0,
      lastUrl: null,
      logoList: null,
    };
  },
  computed: {
    ...mapGetters('plp', {
      gridType: 'getGridType',
    }),
    ...mapGetters('filter', {
      currentFilterUrl: 'getCurrentFilterUrl',
      isSearchPage: 'getIsSearchPage',
      searchTerm: 'getSearchTerm',
      selectedSorting: 'getSelectedSorting',
      countResultFilters: 'getCountResultFilters',
      sortHasBeenClickedByUser: 'getSortHasBeenClickedByUser',
      selectedDeliveryType: 'getSelectedDeliveryType',
    }),
    ...mapGetters('page', {
      slugs: 'getSlugs',
      searchfilter: 'getSearchfilter',
      actualCategoryPath: 'getActualCategoryPath',
      locale: 'getLocale',
      getGrid: 'getGrid',
      breadcrumbs: 'getBreadcrumbs',
      isApp: 'isApp',
      imageSearchID: 'getSearchImageID',
      getCountProducts: 'hasProducts',
      megadropCustom: 'getMegadropCustom',
      isModal: 'isModal',
      hasAcceptedCookies: 'hasAcceptedCookies',
      isUniforms: 'isUniforms',
      schoolData: 'getSchoolData',
      getSearchSource: 'getSearchSource',
      algoliaInfo: 'getAlgoliaInfo',
    }),
    ...mapGetters('cart', {
      centerDeliveryType: 'getCenterDeliveryType',
      currentDeliveryType: 'getCurrentDeliveryType',
      algoliaUser: 'getAlgoliaUserData',
    }),
    getCurrentPage() {
      return this.page;
    },
    getCurrentOffset() {
      return this.fetchedData.pagination.itemsPerPage * (this.firstItemPage - 1);
    },
    hasColors() {
      return (this.products || []).some((product) => {
        return product._my_colors && product._my_colors.length;
      });
    },
    titleH1() {
      if (this.isUniforms) return this.$t('uniforms.school_products_list-title', { schoolName: this.schoolData.text });
      if (this.fetchedData?._breadcrumb?.subtitle) return this.fetchedData?._breadcrumb?.subtitle;
      return this.fetchedData._title;
    },
    getInternals() {
      return this.fetchedData && this.fetchedData._internals;
    },
    categoryLinks() {
      return this.fetchedData.category_links;
    },
    isBackwardLoading() {
      return this.isLoading && this.loadingDirection == NAVIGATION_TYPES.BACKWARD;
    },
    isForwardLoading() {
      return this.isLoading && this.loadingDirection == NAVIGATION_TYPES.FORWARD;
    },
    isSortingInUrl() {
      return !!UtilsURL.getUrlParam(window.location.search, 'sorting');
    },
    hasToSendAlgoliaEvents() {
      return this.algoliaInfo?.enabled && this.getSearchSource === 'algolia';
    },
  },
  methods: {
    ...mapActions('page', [
      'initPage',
      'setDeliveryFacet',
      'setPagination',
      'getStock',
      'setCountProducts',
      'addPlacements',
      'addGiftsWithStock',
    ]),
    ...mapActions('support', ['initSupport']),
    ...mapActions('newsletter', ['initNewsletter']),
    ...mapActions('plp', ['fetchProducts', 'fetchProductsBySearch', 'changeGrid']),
    ...mapActions('filter', [
      'initFilters',
      'resetState',
      'addCurrentUrl',
      'setLoadingFilter',
      'setCurrentlySelectedDeliveryTypes',
      'setSelectedDeliveryType',
      'setSortHasBeenClickedByUser',
    ]),
    handleModal() {
      this.openModalFilter = !this.openModalFilter;
    },
    getAllFilters(inputParameters) {
      const {
        selectedFilters = [],
        previousFilters,
        pagination,
        sortings,
        selectedSorting,
        addToCurrentFilters = false,
      } = { ...inputParameters };
      const filters = { ...previousFilters };
      if (filters?._filters_trail && selectedFilters && selectedFilters.length > 0) {
        const trailSelectedFilters = selectedFilters.split('/');
        const allTrails = [...trailSelectedFilters, ...filters._filters_trail];
        filters._filters_trail = this.getMergedFilters([...new Set(allTrails)]);
      }
      const finalFilters = {
        ...filters,
        pagination,
        _sortings: sortings,
        _selected_sorting: selectedSorting,
        addToCurrentFilters: addToCurrentFilters,
      };
      return finalFilters;
    },
    getMergedFilters(filters) {
      const types = new Set(
        filters.map((filter) => {
          const [type] = filter.split('::');
          if (!type) return filter;
          return type;
        }),
      );
      const mergedFilters = new Set();
      types.forEach((type) => {
        mergedFilters.add(filters.find((filter) => filter.includes(type)));
      });
      return [...mergedFilters];
    },
    getSearchParamFromInternals(internals) {
      const SIMPLE_SEARCH_PARAM = 's';
      const EXTENDED_SEARCH_PARAM = 'ss';
      if (internals && internals.req && internals.req.query) {
        if (internals.req.query[SIMPLE_SEARCH_PARAM]) {
          return SIMPLE_SEARCH_PARAM;
        }
        if (internals.req.query[EXTENDED_SEARCH_PARAM]) {
          return EXTENDED_SEARCH_PARAM;
        }
      }
      return null;
    },
    async getProductsList(currentOptions, needReset = false) {
      //TODO: Refactorizar
      if (needReset) {
        currentOptions.currentPage = 1;
        currentOptions.itemsPerPage = this.imageSearchID ? 36 : 12;
        currentOptions.direction = 'FORWARD';
        this.previousPage = 1;
        this.fetched.first = false;
        this.fetched.last = false;
        this.fetchedPages = [1];
        this.fetchedPage = 1;
        window.scrollTo(0, 0);
        this.setLoadingFilter(true);
      }
      this.forceRichRelevanceRecall = true;
      this.filters = this.currentFilterUrl ? this.currentFilterUrl : null;
      this.page = currentOptions.currentPage;
      const direction = currentOptions.direction;
      const shouldFetch = this.shouldFetch(direction, needReset);
      if (!shouldFetch) {
        return;
      }
      const thirdDriverParam = UtilsURL.getCurrentParam('third');
      const payload = {
        deliveryType: this.selectedDeliveryType,
        page: this.page,
        slugs: this.slugs,
        searchfilter: this.searchfilter,
        filters: this.filters,
        searchTerm: encodeURIComponent(this.searchTerm),
        searchType: this.getSearchParamFromInternals(this.fetchedData._internals),
        sorting: this.selectedSorting,
        sortHasBeenClicked: this.sortHasBeenClickedByUser,
        image_id: this.imageSearchID,
        isUniforms: this.isUniforms,
        ...(this.isUniforms && { schoolId: this.schoolData?.id }),
        ...(thirdDriverParam && { third: thirdDriverParam }),
      };
      if (!needReset) payload.showDimensions = 'none';
      if (this.fetchedData._amv) payload.isAMV = true;

      const method = this.isSearchPage ? 'fetchProductsBySearch' : 'fetchProducts';
      const response = await this[method](payload);
      await this.handleProductListResponse(response, needReset, direction);
    },

    async handleProductListResponse(response, needReset, direction) {
      this.forceRichRelevanceRecall = false;
      if (!response?.success) {
        this.setPaginationEdges();
        this.setLoadingFilter(false);
        this.showRichRelevanceContent = false;
        return console.error('[ProductList] Error fetching products');
      }
      if (!response?.data?.products?.length) {
        this.setLoadingFilter(false);
        const { filters, pagination, _sortings, _selected_sorting, _delivery_facet } = response.data;
        this.initFilters(
          this.getAllFilters({
            selectedFilters: [],
            previousFilters: filters,
            pagination,
            sortings: _sortings,
            selectedSorting: _selected_sorting,
          }),
        );
        if (_delivery_facet) this.setDeliveryFacet(_delivery_facet);
        this.updateUrl(response.data?.title);
        this.page = 1;
        this.putHandleUrl(this.page);
        this.products = [];
        this.setCountProducts(this.countResultFilters);
        this.showRichRelevanceContent = false;
        return console.info('[ProductList] Products not found for this search');
      }

      const { pagination, placements, paginatedDatalayer } = response.data;
      let { products } = response.data;
      this._datalayerProducts = paginatedDatalayer.products;
      this.fetchedPages.push(pagination?._current);
      this.previousPage = this.page;

      const ids = this.getProductIdsToCheckStock(products);
      if (ids.length) {
        const stock = await this.getStockByIds(ids, 'product');
        if (this.hasToSendAlgoliaEvents) {
          const objectIDs = this.getObjectIds(products);
          const payload = {
            index: products[0].algoliaData?.index_name,
            objectIDs: objectIDs,
            userToken: this.algoliaUser?.algoliaUserToken,
            authenticatedUserToken: this.algoliaUser?.algoliaAuthenticatedUserToken,
          };
          AlgoliaEvents.sendViewedObjectsId(payload);
        }
        mergePromosSPO(stock, products);
        products = StockSchema.setProductsStock(stock, products);
        this.handlePromotionalProductsStock(stock);
      }

      this.fetchedPage = pagination?._current;
      this.fetched.first = this.fetched.first || this.page === 1;
      this.fetched.last = this.fetched.last || this.page === pagination._total;
      this.fetched.total = pagination._total;
      this.setCountProducts(this.countResultFilters);

      defer(() => {
        this.productPushIndex = UtilsAnalytics.sendProductsPush(
          products || [],
          this.productPushIndex,
          this._datalayerProducts,
        );
      }, true);

      if (needReset) {
        this.setLoadingFilter(false);
        const { filters, _sortings, _selected_sorting, _delivery_facet } = response.data;
        this.initFilters(
          this.getAllFilters({
            selectedFilters: this.filters,
            previousFilters: filters,
            pagination,
            sortings: _sortings,
            selectedSorting: _selected_sorting,
          }),
        );
        this.setDeliveryFacet(_delivery_facet);
        this.page = 1;
        this.products = products;
        this.updateUrl(response.data?.title);
        this.putHandleUrl(this.page);
        this.setCountProducts(this.countResultFilters);
      } else {
        if (this.fetched.last) {
          this.showRichRelevanceContent = this.richRelevanceIsEnabled ? true : false;
        }
        if (direction === NAVIGATION_TYPES.FORWARD) {
          this.products.push(...products);
        }
        if (direction === NAVIGATION_TYPES.BACKWARD) {
          this.setScrolledState(document.body.scrollHeight);
          this.scrollObserver.observe(document.body);
          this.products.unshift(...products);
        }
        if (placements && placements.cover) this.updateUrl(response.data?.title);
      }

      this.updateFirstVisiblePage();
      this.paginatedPlacements[this.page] = placements || {};
      this.addPlacements(placements);
    },

    async getStockByIds(ids, type) {
      const response = await this.getStock({ ids, type });
      if (!response.success || !response.data) {
        console.error('[ProductList] Error fetching stock. Giving default response.');
        return StockSchema.getDefaultStockResponse(ids);
      }
      return response.data;
    },
    setPaginationEdges() {
      const [lastFetchedPage] = this.fetchedPages.slice(-1);
      if (this.page > lastFetchedPage) return (this.fetched.last = true);
      this.fetched.first = true;
    },
    getObjectIds(products) {
      return products
        .map((product) => {
          product.stock = {};
          const hasAvailableSkus =
            Array.isArray(product._my_colors) &&
            product._my_colors.some((color) => StockSchema.shouldCheckStock(color.add_to_cart));
          const isTicketsProduct = product.isTicketsProduct;
          return !isTicketsProduct && hasAvailableSkus && product.objectId;
        })
        .filter((product) => product);
    },
    getProductIdsToCheckStock(products) {
      return products
        .map((product) => {
          product.stock = {};
          const hasAvailableSkus =
            Array.isArray(product._my_colors) &&
            product._my_colors.some((color) => StockSchema.shouldCheckStock(color.add_to_cart));
          const isTicketsProduct = product.isTicketsProduct;
          return !isTicketsProduct && hasAvailableSkus && product.id;
        })
        .filter((product) => product);
    },
    initStock() {
      if (this.products.length === 0) return;
      if (this.fetched.initialStock) return;

      const ids = this.getProductIdsToCheckStock(this.products);
      if (!ids.length) return;

      if (this.hasToSendAlgoliaEvents) {
        const objectIDs = this.getObjectIds(this.products);
        const payload = {
          index: this.products[0].algoliaData?.index_name,
          objectIDs: objectIDs,
          userToken: this.algoliaUser?.algoliaUserToken,
          authenticatedUserToken: this.algoliaUser?.algoliaAuthenticatedUserToken,
        };
        AlgoliaEvents.sendViewedObjectsId(payload);
      }

      const type = 'product';
      const self = this;
      return this.getStock({ ids, type })
        .then((productsStock) => {
          if (!productsStock || !productsStock.success) {
            this.fetched.initialStock = true;
            const defaultStockResponse = StockSchema.getDefaultStockResponse(ids);
            mergePromosSPO(defaultStockResponse, this.products);
            self.products = StockSchema.setProductsStock(defaultStockResponse, this.products);
            console.error('[ProductList] Error fetching initial stock. Giving default response.');
            return defaultStockResponse;
          }
          this.fetched.initialStock = true;
          mergePromosSPO(productsStock.data, this.products);
          self.products = StockSchema.setProductsStock(productsStock.data, this.products);
          this.handlePromotionalProductsStock(productsStock.data);
        })
        .catch((error) => {
          this.fetched.initialStock = true;
          const defaultStockResponse = StockSchema.getDefaultStockResponse(ids);
          mergePromosSPO(defaultStockResponse, this.products);
          self.products = StockSchema.setProductsStock(defaultStockResponse, this.products);
          console.error('[ProductList] Error fetching initial stock. Giving default response.', error);
          return defaultStockResponse;
        });
    },
    handlePromotionalProductsStock(productsStock) {
      const giftReferences = StockSchema.getAllGiftReferencesFromStock(productsStock);
      if (giftReferences.length) {
        this.getStock({ ids: giftReferences, type: 'gifts' }).then(async (giftStock) => {
          await this.addGiftsWithStock(giftStock.data.ADD ?? []);
          self.products = StockSchema.setProductPromosWithStock(this.products, productsStock, giftStock.data);
        });
      }
    },
    scrollToProductId(idProduct) {
      if (idProduct) {
        const anchor = idProduct.startsWith('place-')
          ? document.getElementById(idProduct)
          : document.getElementById(`${idProduct}anchor-product`);
        if (anchor) {
          anchor.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    },
    isEquivalentUrl(currentUrl, newUrl) {
      const pageRegex = /\/\d+\//;
      const currentUrlWithoutPage = currentUrl?.replace(pageRegex, '/');
      const newUrlWithoutPage = newUrl?.replace(pageRegex, '/');
      return currentUrlWithoutPage === newUrlWithoutPage;
    },
    updateUrl(newTitle = undefined) {
      const { page, slugs, searchfilter } = this;
      const params = {
        page,
        slugs,
        searchfilter,
        filters: this.currentFilterUrl,
      };

      const path = this.isSearchPage
        ? UtilsProductList.getSlugsPathBySearch(params)
        : UtilsProductList.getSlugsPath(params);

      const query = UtilsURL.updateSearchAndSortingParamsInURL(
        this.getInternals,
        this.selectedSorting,
        this.searchTerm,
        this.imageSearchID,
        this.selectedDeliveryType,
      );
      if (!this.sortHasBeenClickedByUser && query.sorting) delete query['sorting'];
      const url = UtilsURL.getUrlWithQuery(window.location, path, query);
      if (newTitle) window.document.title = newTitle;
      const title = window.document.title;
      window.history.scrollRestoration = 'manual';
      if (this.isEquivalentUrl(url, this.lastUrl)) {
        window.history.replaceState({}, title, new URL(url));
      } else {
        window.history.pushState({}, title, new URL(url));
      }
      this.lastUrl = url;
    },
    getProductPLPUrl(page) {
      const { slugs, searchfilter } = this;
      const params = {
        page,
        slugs,
        searchfilter,
        filters: this.currentFilterUrl,
        route: this.getInternals?.req?.moonshine?.route,
      };
      const path = this.isSearchPage
        ? UtilsProductList.getSlugsPathBySearch(params)
        : UtilsProductList.getSlugsPath(params);

      return UtilsURL.getUrl(window.location.origin, path, window.location.search);
    },
    updateHistoryState(props) {
      const idProduct = props?.idProduct || window?.history?.state?.idProduct;
      const page = props?.page;
      const url = this.getProductPLPUrl(page);
      const currentState = window.history.state;
      const newState = { ...currentState, idProduct };
      window.history.replaceState(newState, '', url);
    },
    handlePlacementExternalLinkClicked(placementId, index) {
      const page = this.getPageFromProductIndex(index);
      this.updateHistoryState({ idProduct: placementId, page: page });
    },
    putHandleUrl(page, previousPage) {
      if (this.isLoading) {
        this.previousPageToHandleUrl = this.pageToHandleUrl || this.page;
        return (this.pageToHandleUrl = page);
      }
      this.previousPageToHandleUrl = 0;
      this.pageToHandleUrl = 0;
      this.previousPage = previousPage || this.page;
      this.page = page;

      const state = {
        page,
        action: this.previousPage - this.page > 0 ? 'back' : 'forward',
      };

      const { slugs, searchfilter } = this;
      const params = {
        page: this.fetchedData.pagination.count === 12 ? 1 : page,
        slugs,
        searchfilter,
        filters: this.currentFilterUrl,
        route: this.getInternals?.req?.moonshine?.route,
      };
      const path = this.isSearchPage
        ? UtilsProductList.getSlugsPathBySearch(params)
        : UtilsProductList.getSlugsPath(params);

      const url = UtilsURL.getUrl(window.location.origin, path, window.location.search);
      const title = window.document.title;
      window.history.scrollRestoration = 'manual';
      if (this.isEquivalentUrl(url.href, this.lastUrl)) {
        window.history.replaceState(state, title, url);
      } else {
        window.history.pushState(state, title, url);
      }
      this.lastUrl = url.href;
    },
    async handleFetch(payload) {
      const self = this;
      if (this.fetched.products) return;
      this.fetched.products = true;
      this.isLoading = true;
      await self.getProductsList(payload);
      initCarousels();
      this.isLoading = false;
      this.fetched.products = false;

      if (this.pageToHandleUrl) {
        this.putHandleUrl(this.pageToHandleUrl, this.previousPageToHandleUrl);
      }
      if (!this.addedPromotionsPage.includes(this.fetchedPage)) {
        const placements = this.paginatedPlacements[this.fetchedPage];
        UtilsAnalytics.sendPromotionPushScroll(placements);
        this.addedPromotionsPage.push(this.fetchedPage);
      }
    },
    handleNavigation(event) {
      window.addEventListener('popstate', () => {
        window.location.reload();
      });

      if (!event || !event.state) return;
      return window.location.reload();
    },
    shouldFetch(direction, needReset) {
      this.loadingDirection = direction || NAVIGATION_TYPES.FORWARD;
      if (direction === NAVIGATION_TYPES.FORWARD) {
        const notShouldFetchForward =
          this.fetchedPage >= this.page ||
          this.page > this.fetched.total ||
          this.fetchedPages.includes(this.page) ||
          this.fetched.last;
        if (notShouldFetchForward && !needReset) return false;
      } else if (direction === NAVIGATION_TYPES.BACKWARD) {
        const notShouldFetchBackward =
          (this.previousPage === 1 && this.page === 1) ||
          this.previousPage === this.page ||
          this.fetchedPages.includes(this.page) ||
          this.fetched.first;
        if (notShouldFetchBackward && !needReset) return false;
      }
      return true;
    },
    buildProductPreviewDatalayer(productData, index) {
      return UtilsAnalytics.buildProductDatalayer(productData, index);
    },
    updateFirstVisiblePage() {
      if (!this.firstItemPage || this.page < this.firstItemPage) this.firstItemPage = this.page;
    },
    showFooterPlacement(productIndex, productCount) {
      // When scrolling down to the bottom of the last page,
      // this.page sums 1 despite no other pages are loaded.
      const lastPage =
        this.page >= this.fetchedData.pagination?._total ? this.fetchedData.pagination?._total : this.page;
      if (!this.hasPlacement(lastPage, 'footer')) return false;
      if (this.nextBlockStart > 0) {
        return productIndex == this.nextBlockStart - 1;
      } else {
        return (
          this.getPageFromProductIndex(productIndex) == this.fetchedData.pagination?._total &&
          productIndex == productCount - 1
        );
      }
    },
    initPaginatedPlacements(placements) {
      this.paginatedPlacements[this.page] = placements || {};
    },
    isPageFirstProduct(productIndex) {
      return productIndex % this.fetchedData.pagination.itemsPerPage === 0;
    },
    getPageFromProductIndex(productIndex) {
      return parseInt(productIndex / this.fetchedData.pagination.itemsPerPage) + this.firstItemPage;
    },
    hasPlacement(page, type = null) {
      const pagePlacements = this.paginatedPlacements[page];
      if (!type && pagePlacements) return !!Object.keys(pagePlacements).length;
      return UtilsPlacements.hasPlacementHtml(pagePlacements, type);
    },
    getAfterRowPlacementStyle(productIndex, row) {
      const page = this.getPageFromProductIndex(productIndex);
      let sumRows = 1;
      if (row == 'after_second_row' || row == 'mid_page') {
        sumRows = this.hasPlacement(page, 'after_first_row') ? 3 : 2;
      }

      const itemsPerRow = this.gridType == GRID_TYPES.LITTLE_PRODUCTS ? 4 : 2;
      const actualRow = UtilsPlacements.getProductRowInProductListWithPlacements(
        productIndex,
        page,
        this.fetchedData.pagination.itemsPerPage,
        itemsPerRow,
        this.paginatedPlacements,
      );
      const rowsMobile = actualRow.rowsMobile + sumRows;
      const rowsDesktop = actualRow.rowsDesktop + sumRows;

      return {
        '--row': rowsMobile,
        '--row-desktop': rowsDesktop,
      };
    },
    handleScrollResize() {
      const height = document.body.scrollHeight;
      const newScrollTop = height - this.scrolledHeight;
      window.scrollTo({ top: newScrollTop });

      this.setScrolledState(height);
      this.scrollUnobserver();
    },
    updateScrolledHeight() {
      // if(this.scrolledHeight + this.scrolledTop != document.body.scrollHeight) return;
      if (this.scrolledTop == window.pageYOffset) return;
      this.scrolledHeight -= window.pageYOffset - this.scrolledTop;
      this.scrolledTop = window.pageYOffset;
    },
    setScrolledState(height) {
      this.scrolledTop = window.pageYOffset;
      this.scrolledHeight = height - window.pageYOffset;
      // if(typeof InstallTrigger !== 'undefined') window.addEventListener('scroll', this.updateScrolledHeight);
      // window.addEventListener('DOMMouseScroll', this.updateScrolledHeight);
    },
    initScrollObserver() {
      this.scrollObserver = new ResizeObserver(this.handleScrollResize);
      this.scrollUnobserver = debounce(() => {
        this.scrollObserver.unobserve(document.body);
        // if(typeof InstallTrigger !== 'undefined') window.removeEventListener('scroll', this.updateScrolledHeight);
        // window.removeEventListener('DOMMouseScroll', this.updateScrolledHeight);
      }, 100);
    },
    scrollListener() {
      let timer = null;

      document.addEventListener(
        'scroll',
        throttle(() => {
          this.isScrolling = true;
          if (timer !== null) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            this.isScrolling = false;
          }, 650);
        }, 500),
      );
    },
    initCurrentlyeSelectedDeliveryTypes() {
      const deliverySelectedFilter = {
        id: FACET_DELIVERY.ECI_EXPRESS,
        isChecked: true,
        deliveryType: DELIVERY_PARAMS.DELIVERY,
      };
      const clickAndCollectSelectedFilter = {
        id: FACET_DELIVERY.CLICK_AND_CAR,
        isChecked: true,
        deliveryType: DELIVERY_PARAMS.COLLECT,
      };

      if (UtilsURL.hasParam(DELIVERY_PARAMS.DELIVERY_KEY, DELIVERY_PARAMS.DELIVERY_AND_COLLECT)) {
        this.setSelectedDeliveryType(DELIVERY_PARAMS.DELIVERY_AND_COLLECT);
        this.setCurrentlySelectedDeliveryTypes([deliverySelectedFilter, clickAndCollectSelectedFilter]);
      } else if (UtilsURL.hasParam(DELIVERY_PARAMS.DELIVERY_KEY, DELIVERY_PARAMS.COLLECT)) {
        this.setSelectedDeliveryType(DELIVERY_PARAMS.COLLECT);
        this.setCurrentlySelectedDeliveryTypes([clickAndCollectSelectedFilter]);
      } else if (UtilsURL.hasParam(DELIVERY_PARAMS.DELIVERY_KEY, DELIVERY_PARAMS.DELIVERY)) {
        this.setSelectedDeliveryType(DELIVERY_PARAMS.DELIVERY);
        this.setCurrentlySelectedDeliveryTypes([deliverySelectedFilter]);
      }
    },
  },
  mounted() {
    const { idProduct: productToScroll } = window.history.state || {};
    this.lastUrl = window?.location?.href;
    this.isMounted = true;
    this.initStock();
    this.handleNavigation();
    const placements = this.fetchedData.placements;
    if (!placements || (!placements.cover && !placements.full_cover)) {
      this.productPushIndex = UtilsAnalytics.sendProductsPush(
        this.products || [],
        this.productPushIndex,
        this.fetchedData._datalayer[0]?.products || [],
      );
    }
    this.scrollListener();
    this.addedPromotionsPage.push(this.fetchedPage);

    let megadropPromos = null;

    if (this.megadropCustom?.data) {
      megadropPromos = UtilsAnalytics.checkMegadropPromos(this.megadropCustom.data);
    }

    UtilsAnalytics.sendPromotionPushInitial(megadropPromos);

    this.initScrollObserver();
    this.$nextTick(initCarousels);
    if (this.isSortingInUrl) {
      this.setSortHasBeenClickedByUser(true);
    }
    this.initCurrentlyeSelectedDeliveryTypes();
    this.showRichRelevanceContent =
      this.richRelevanceIsEnabled &&
      (this.fetchedData.pagination?._current == this.fetchedData.pagination?._total ||
        this.fetchedData.products?.length === 0);
    setTimeout(() => this.scrollToProductId(productToScroll), 500);
  },
  beforeCreate() {
    this.fetchedData = import.meta.env.SSR ? useSSRContext() : window.__MOONSHINE_STATE__;
  },
  created() {
    AlgoliaEvents.initAlgoliaEventsUrl(this.algoliaInfo?.events);
    this._datalayerProducts = this.fetchedData?._datalayer?.[0]?.products || [];
    this.products = this.fetchedData.products;
    this.fetched.first = this.fetchedData.pagination?._current === 1;
    this.fetched.last = this.fetchedData.pagination?._current === this.fetchedData.pagination?._total;
    this.fetched.total = this.fetchedData.pagination?._total;
    this.page = this.fetchedData.pagination?._current;
    this.firstItemPage = this.page;
    this.previousPage = this.page;

    this.fetchedPages.push(this.page);
    this.fetchedPage = this.page;

    this.initPaginatedPlacements(this.fetchedData.placements || null);
    this.logoList = this.fetchedData.placements?.logo_list;
  },
  destroyed() {
    this.scrollObserver.disconnect();
  },
};

export default producListLogicMixin;
