<template>
  <div>
    <div
      v-show="!isLoading"
      class="infinite_scroll-backward"></div>
    <Layout
      :leanLayout="!!fetchedData._lean_layout"
      :hasLogoList="showLogoList"
      :hasContextMenu="!!isValidPlace && showContextMenu"
      :showStickyInApp="showStickyinApp"
      :bandPlacement="bandPlacement"
      :isbnList="isbnList"
      :isTextBooksCategory="isTextBooksCategory"
      @scrolling="handleScroll"
      @reset-isbn="resetIsbn">      
      <Breadcrumbs
        v-if="isAMV || showBreadcrumbs"
        :breadcrumbs="breadcrumbs" />
      <LogoList
        v-if="showLogoList"
        :logo="logoList" />
      <ContextMenu v-if="isValidPlace && showContextMenu" />
      <div
        ref="stickySentinel"
        style="visibility: hidden"></div>
      <template v-if="hasPlacement(page, 'full_cover')">
        <Sheet
          v-if="hasPlacement(page, 'hero')"
          :placement="paginatedPlacements[page].hero" />
        <Sheet
          v-if="hasPlacement(page, 'header')"
          :placement="paginatedPlacements[page].header" />
        <Sheet
          class="is_full_cover"
          :placement="paginatedPlacements[page].full_cover" />
      </template>
      <div
        v-else
        id="products-list"
        :class="'products_list-container ' + (hasColors ? '_with-colors' : '')">
        <div
          v-show="!isLoading && products?.length"
          class="infinite_scroll-backward"></div>
        <Sheet
          v-if="hasPlacement(page, 'brand_separator') || hasPlacement(page, 'over_headers')"
          :placement="paginatedPlacements[page].brand_separator || paginatedPlacements[page].over_headers" />
        <LazyHydrationWrapper :never="true">
          <TitlePLP
            :title="titleH1"
            :is-search-page="isSearchPage"
            :isUniforms="isUniforms" />
        </LazyHydrationWrapper>
        <SearchImageResult
          v-if="searchImageUrl"
          :img="searchImageUrl"
          :count="getCountProducts" />
        <CcPageForm
          v-if="navigationMode === 'textbook_cc' && isTextBooksCategory"
          ref="cc_page_form_ref" />
        <TextBooksSearchContainer
          v-if="isTextBooksCategory"
          @doClientSearch="handleTextBooksSearch" />
        <UniformsHeader
          v-if="isUniforms"
          :school="schoolData" />
        <ProductListBar
          :title="fetchedData._title"
          :scrolledTop="scrolledTop"
          @handle-filter="getProductsList" />
        <div
          ref="stickySentinel"
          style="visibility: hidden"></div>
        <div
          v-if="!products.length"
          class="products_list-container-empty_search">
          <p>{{ $t('search.empty.search') }}</p>
        </div>

        <div
          v-if="hasPlacement(page, 'cover')"
          style="display: flex">
          <div style="flex-grow: 1; max-width: 100%">
            <Sheet
              v-if="hasPlacement(page, 'hero')"
              :placement="paginatedPlacements[page].hero" />
            <Sheet
              v-if="hasPlacement(page, 'header')"
              :placement="paginatedPlacements[page].header" />
            <Sheet
              v-if="hasPlacement(page, 'cover')"
              :placement="paginatedPlacements[page].cover" />
          </div>
        </div>
        <Loading
          class="loader-backward"
          :loading="isBackwardLoading" />
        <InfiniteScroll
          v-if="!hasPlacement(page, 'cover')"
          :page="page"
          :itemsPerPage="12"
          :elements="scrollSentinelList"
          :isFetching="isLoading"
          :class="{ _has_context_menu: isValidPlace && showStickyinApp }"
          @callback="handleFetch">
          <ul
            itemtype="https://schema.org/ItemList"
            itemscope
            class="products_list"
            :class="gridType"
            :aria-label="$t('product_list')">
            <template
              v-for="(product, index) in products"
              :key="`preview-key-${product.id}`">
              <template v-if="isPageFirstProduct(index)">
                <li
                  v-if="hasPlacement(getPageFromProductIndex(index), ['hero', 'header', 'top_page'])"
                  :key="`upper-placement-${index}`"
                  class="products_list-item products_list-item--full js-products_list-item--first"
                  style="flex-direction: column">
                  <Sheet
                    v-if="hasPlacement(getPageFromProductIndex(index), 'hero')"
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].hero"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                  <Sheet
                    v-if="hasPlacement(getPageFromProductIndex(index), 'header')"
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].header"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                  <Sheet
                    v-if="hasPlacement(getPageFromProductIndex(index), 'top_page')"
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].top_page"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                </li>
                <li
                  v-if="hasPlacement(getPageFromProductIndex(index), 'after_first_row')"
                  :key="`after-first-row-placement-${index}`"
                  class="products_list-item products_list-item--full calculated_after_row calculated_after_row--first js-products_list-item--first"
                  :style="getAfterRowPlacementStyle(index, 'after_first_row')"
                  :data-product-id="paginatedPlacements[getPageFromProductIndex(index)].after_first_row.id">
                  <Sheet
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].after_first_row"
                    :containerClass="'c12'"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                </li>
                <li
                  v-if="hasPlacement(getPageFromProductIndex(index), 'after_second_row')"
                  :key="`after-second-row-${index}`"
                  class="products_list-item products_list-item--full calculated_after_row calculated_after_row--second js-products_list-item--first"
                  :style="getAfterRowPlacementStyle(index, 'after_second_row')"
                  :data-product-id="paginatedPlacements[getPageFromProductIndex(index)].after_second_row.id">
                  <Sheet
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].after_second_row"
                    :containerClass="'c12'"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                </li>
                <li
                  v-if="hasPlacement(getPageFromProductIndex(index), 'mid_page')"
                  :key="`after-mid-row-${index}`"
                  class="products_list-item products_list-item--full calculated_after_row calculated_after_row--second js-products_list-item--first"
                  :style="getAfterRowPlacementStyle(index, 'mid_page')"
                  :data-product-id="paginatedPlacements[getPageFromProductIndex(index)].mid_page.id">
                  <Sheet
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].mid_page"
                    :containerClass="'c12'"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                </li>
              </template>
              <li
                v-if="product.placement"
                :key="`product-preview-placement-${product.id}-${product.placement.id}`"
                class="products_list-item">
                <Sheet
                  :placement="product.placement"
                  :index="index"
                  :type="`total_look`"
                  @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
              </li>
              <li
                itemprop="itemListElement"
                itemscope
                itemtype="https://schema.org/ListItem"
                class="products_list-item"
                data-synth="LOCATOR_PRODUCT_PREVIEW">
                <meta
                  itemprop="position"
                  :content="index" />
                <meta
                  itemprop="name"
                  :content="product.title" />
                <ProductPreview
                  :key="`preview-key-${product.id}-${currentFilterUrl}`"
                  :currentPage="getPageFromProductIndex(index)"
                  :dataLayer="buildProductPreviewDatalayer(product, index + 1) || {}"
                  :firstItemPage="firstItemPage"
                  :gridType="gridType"
                  :isAMV="fetchedData._amv"
                  :isFirstItem="(index + 1) % 12 === 1"
                  :isLastItem="(index + 1) % 12 === 0"
                  :isPlp="true"
                  :page="page"
                  :position="index + 1"
                  :product="product"
                  @putHandleUrl="putHandleUrl" />
              </li>
              <template v-if="showFooterPlacement(index, products?.length)">
                <li
                  :key="`footer-placement-${index}`"
                  class="products_list-item products_list-item--full">
                  <Sheet
                    :placement="paginatedPlacements[getPageFromProductIndex(index)].footer"
                    :containerClass="'c12'"
                    @externalLinkClicked="(placementId) => handlePlacementExternalLinkClicked(placementId, index)" />
                </li>
              </template>
              <template
                v-if="
                  fetchedData.placements &&
                    fetchedData.placements.brand_separator &&
                    fetchedData.placements.brand_separator.html
                ">
              </template>
            </template>
          </ul>
        </InfiniteScroll>
        <div
          v-show="!isLoading && products?.length"
          class="infinite_scroll-forward"
          :class="{
            'infinite_scroll-forward--no-backward': fetched.first,
          }"></div>
        <Loading :loading="isForwardLoading" />
      </div>
      <div
        v-if="!fetchedData._lean_layout && fetchedData.placements && fetchedData.placements.nugget && showSEOPlacements"
        class="nugget-container">
        <Sheet :placement="fetchedData.placements.nugget" />
      </div>
      <RichRelevance
        v-if="isMounted && richRelevanceIsEnabled && !isModal && hasAcceptedCookies.PERSONALIZATION && !isCartLoading"
        :brandName="fetchedData.brand_name"
        :categoryId="getCurrentCategory?.id"
        :forceRecall="forceRichRelevanceRecall"
        :pageType="getRichRelevancePageType"
        :searchTerm="searchTerm"
        :showContent="showRichRelevanceContent" />
      <GoToTop v-if="!hasMountedParallaxFooterContainer" />
      <ComparatorPreview v-if="!customizations?.hide_comparator" />
    </Layout>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapGetters } from 'vuex';
  import Layout from 'Components/Layout/Layout.vue';
  import ProductPreview from 'Components/ProductPreview/ProductPreview.vue';
  import ProducListLogicMixin from 'CommonMixins/productListLogic.js';
  import LayoutLogicMixin from 'CommonMixins/layoutLogic';
  import Sheet from 'CommonComponents/Sheets/Render.vue';
  import ProductListBar from 'Components/ProductList/ProductListBar.vue';
  import GoToTop from 'CommonComponents/GoToTop/GoToTop.vue';
  import Loading from 'CommonComponents/Loading/Loading.vue';
  import InfiniteScroll from 'CommonComponents/InfiniteScroll/InfiniteScroll.vue';
  import Breadcrumbs from 'CommonComponents/Breadcrumbs/Breadcrumbs.vue';
  import ContextMenu from 'Components/Layout/Header/ContextMenu/ContextMenu.vue';
  import TitlePLP from 'Components/assets/Title/TitlePLP.vue';
  import { LazyHydrationWrapper } from 'vue3-lazy-hydration';
  import PAGE_TYPES from 'CommonUtils/schemas/pageTypes';
  import PLACE_TYPES from 'Utils/placeTypes';
  import RichRelevance from 'Components/Vendors/RichRelevance/RichRelevance.vue';
  import CookieUtils from 'CommonUtils/cookies.js';
  import ScriptUtils from 'CommonUtils/addScript';
  import NavigatorDetect from 'CommonMixins/navigatorDetect';
  import UniformsHeader from 'Components/Uniforms/UniformsProductListHeader.vue';
  import LogoList from 'Components/Layout/Header/LogoList.vue';

  const SCROLL_DIRECTION = Object.freeze({
    UP: 'UP',
    DOWN: 'DOWN',
  });

  const TEXT_BOOKS_CATEGORY_ID = '999.906419013';

  export default {
    name: 'ProductList',
    components: {
      Breadcrumbs,
      ComparatorPreview: defineAsyncComponent(() => import('Components/Comparator/ComparatorPreview.vue')),
      ContextMenu,
      GoToTop,
      InfiniteScroll,
      Layout,
      LazyHydrationWrapper,
      Loading,
      ProductListBar,
      ProductPreview,
      RichRelevance,
      SearchImageResult: defineAsyncComponent(() => import('CommonComponents/Search/Image/SearchImageResult.vue')),
      Sheet,
      TitlePLP,
      UniformsHeader,
      CcPageForm: defineAsyncComponent(() => import('Components/Books/CcPageForm.vue')),
      TextBooksSearchContainer: defineAsyncComponent(() => import('Components/Books/TextBooksSearchContainer.vue')),
      LogoList,
    },
    mixins: [ProducListLogicMixin, LayoutLogicMixin, NavigatorDetect],
    provide: function () {
      return {
        assetsBase: this.fetchedData?._assets_base,
        bazaarVoiceSEO: this.fetchedData?.vendors?.BazaarVoice?.seo?.url,
        crossSellingType: undefined,
        hasBazaarVoice: this.fetchedData?.vendors?.BazaarVoice?.hasBazaarVoice,
        hidePromotions: false,
        homeDomain: this.fetchedData?._home_domain,
        internals: this.fetchedData?._internals,
        isPLP: true,
        isScrolling: () => this.isScrolling,
        storeId: this.fetchedData?.storeId,
        updateHistoryState: (e) => this.updateHistoryState(e),
        isCampaignsSiteMode: this.fetchedData?.is_campaigns_site_mode,
      };
    },
    emits: ['isbnListChanged', 'saveCcData'],
    data() {
      return {
        scrollSentinelList: [
          { selector: '.infinite_scroll-forward', direction: 'FORWARD' },
          { selector: '.infinite_scroll-backward', direction: 'BACKWARD' },
        ],
        scrollDirection: null,
        SCROLL_DIRECTION,
        iObserver: null,
        stickySentinel: null,
        isStickySentinelVisible: true,
        PAGE_TYPES,
        isbnList: [],
        navigationMode: '',
        textBooksCcData: {},
      };
    },
    computed: {
      ...mapGetters('page', {
        placeType: 'getPlaceType',
        isAMV: 'getIsAMV',
        searchImageUrl: 'getSearchImage',
        hasMountedParallaxFooterContainer: 'hasMountedParallaxFooterContainer',
        customizations: 'getLayoutOptions',
        isApp: 'isApp',
        isEciAppFocus: 'isEciAppFocus',
        isNewApp: 'isNewApp',
      }),
      ...mapGetters('filter', {
        currentFilterUrl: 'getCurrentFilterUrl',
      }),
      ...mapGetters('cart', {
        isCartLoading: 'getLoadingStatus',
      }),
      isValidPlace() {
        return this.placeType != PLACE_TYPES.NOT_PLACE;
      },
      dataLayer() {
        return this.fetchedData._datalayer[0].products || [];
      },
      bandPlacement() {
        return this.fetchedData && this.fetchedData.placements && this.fetchedData.placements.band
          ? this.fetchedData.placements.band
          : null;
      },
      getRichRelevancePageType() {
        if (this.searchTerm) return PAGE_TYPES.SEARCH_PAGE;
        if (this.fetchedData.page_type == 'js_brand_page') return PAGE_TYPES.BRAND_PAGE;
        return PAGE_TYPES.CATEGORY_PAGE;
      },
      getCurrentCategory() {
        const categories = this.fetchedData?.products[0]?.categories;
        if (categories?.length) {
          return categories[categories.length - 1];
        }
        return undefined;
      },
      showStickyinApp() {
        if (!this.isApp) return true;
        if (this.isStickySentinelVisible) return true;
        return this.scrollDirection === SCROLL_DIRECTION.UP || !this.scrollDirection;
      },
      isTextBooksCategory() {
        return this.getCurrentCategory?.id === TEXT_BOOKS_CATEGORY_ID;
      },
      showSEOPlacements() {
        if (!this.isNewApp && !this.isEciAppFocus) {
          return true;
        }
        return this.isEciAppFocus;
      },
      showLogoList() {
        if (!this.logoList){
          return false;
        }
        if(!this.isApp && !this.isNewApp && !this.isEciAppFocus) {
          return true;
        }
        return (this.isApp && !this.isNewApp) || this.isEciAppFocus;
      },
      showContextMenu() {
        if (!this.isApp && !this.isNewApp && !this.isEciAppFocus) {
          return true;
        }
        return (this.isApp && !this.isNewApp) || this.isEciAppFocus;
      },
      showBreadcrumbs() {
        if (!this.isApp && !this.isNewApp && !this.isEciAppFocus) return true;
        return (this.isApp && !this.isNewApp) || this.isEciAppFocus;
      },
    },
    created() {
      const { code, privacy_link, designers } = this.fetchedData.newsletter_info;
      this.initPage(this.fetchedData);
      this.initSupport(this.fetchedData);
      this.initFilters(this.fetchedData);
      this.initNewsletter({ code, privacy_link, designers });
      this.changeGrid(this.fetchedData._default_grid_disposition);
    },
    mounted() {
      this.enableBackwardOnScroll();
      this.iObserver = new IntersectionObserver(this.handleIntersection);
      this.iObserver.observe(this.$refs.stickySentinel);
      this.navigationMode = CookieUtils.getCookieValue('navigationMode');

      if (this.fetchedData?.vendors?.BazaarVoice?.hasBazaarVoice) {
        this.addBazaarVoiceScripts();
      }
    },
    unmounted() {
      this.iObserver.unobserve(this.$refs.stickySentinel);
    },
    methods: {
      addBazaarVoiceScripts() {
        ScriptUtils.addScriptFromUrl(this.fetchedData?.vendors.BazaarVoice?.url, null, 'eci-bv-js');
        const cookiesPolicy = CookieUtils.getCookieValue('cookiesPolicy');
        const eciMonitorization = CookieUtils.getCookieValue('eci_monitoriz');
        if (!eciMonitorization && (cookiesPolicy == '1' || cookiesPolicy?.charAt(4) == '1')) {
          ScriptUtils.addScriptFromUrl(this.fetchedData?.vendors.BazaarVoice?.seo?.url);
        }
      },
      enableBackwardOnScroll() {
        if (typeof window === 'undefined') return;
        if (window.pageYOffset === 0 && this.firstItemPage > 1) this.scrollVMTo(1);
      },
      handleScroll(e) {
        const { direction } = e;
        if (!direction) return;
        this.scrollDirection = direction;
      },
      handleIntersection([entry]) {
        this.isStickySentinelVisible = entry.isIntersecting;
      },
      isSSRProduct(index) {
        const firstFetchedPage = this.fetchedPages[0];
        const productPage = this.getPageFromProductIndex(index);
        return firstFetchedPage === productPage;
      },
      handleTextBooksSearch(item) {
        this.isbnList = item.label.split(',');
        if (this.navigationMode === 'textbook_cc') {
          const ccPageForm = this.$refs.cc_page_form_ref;
          this.textBooksCcData = {
            textBooksCenter: ccPageForm.selectedCenter || null,
            textBooksCourse: ccPageForm.selectedCourse || null,
            textBooksSeller: ccPageForm.sellerCode || null,
            textBooksUser: ccPageForm.userData || null,
            textBooksIsSchool: ccPageForm.isSchool || null,
          };
          localStorage.setItem('textBooksCcData', JSON.stringify(this.textBooksCcData));
        }
      },
      resetIsbn() {
        this.isbnList = [];
      },
    },
  };
</script>

<style lang="less" scoped>
  .infinite_scroll {
    &-backward {
      width: 100%;
      visibility: hidden;
    }
    &-forward {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 160vh;
      max-height: calc(60% - 160vh);
      visibility: hidden;

      &--no-backward {
        height: 250vh;
        max-height: calc(100% - 100vh);
      }
    }
  }
</style>
